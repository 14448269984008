<template>
  <v-icon :color="iconColorFromFilename(filename)">
    {{ iconNameFromFilename(filename) }}
  </v-icon>
</template>

<script>
/**
 * This component simply takes in a filename, ex "file.pdf",
 * and displays an icon according to its extension
 */
export default {
  name: 'FileTypeIcon',
  props: {
    filename: {
      type: String,
      required: true,
    },
  },
  methods: {
    iconNameFromFilename(filename) {
      const extension =
        this.$helpers.splitFilenameByExtension(filename).extension;
      switch (extension) {
        case 'doc':
        case 'docx':
          return 'mdi-file-word';
        case 'xls':
        case 'xlsx':
          return 'mdi-file-excel';
        case 'pdf':
          return 'mdi-file-pdf-box';
        default:
          return '';
      }
    },
    iconColorFromFilename(filename) {
      const extension =
        this.$helpers.splitFilenameByExtension(filename).extension;
      switch (extension) {
        case 'doc':
        case 'docx':
          return 'msaBlue';
        case 'xls':
        case 'xlsx':
          return 'green';
        case 'pdf':
          return 'red';
        default:
          return '';
      }
    },
  },
};
</script>

<style></style>
