<template>
  <v-card>
    <v-data-table
      :items="histories"
      no-data-text="No history"
      :headers="headers"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
    >
      <template v-slot:[`item.originalFilename`]="{ item }">
        <FileTypeIcon :filename="item.originalFilename" />
        <a @click="downloadDocument(item)">
          {{ item.originalFilename }}
        </a>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterAsLocalDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import FileTypeIcon from '@/components/FileTypeIcon.vue';

export default {
  name: 'DocumentHistory',
  components: { FileTypeIcon },
  props: {
    histories: {
      type: Array,
    },
    isForPublicDocument: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Revision',
          align: 'center',
          value: 'revision',
          class: 'lightBg msaBlue--text',
          width: '100px',
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name',
          class: 'lightBg msaBlue--text',
        },
        {
          text: 'File Name',
          align: 'left',
          value: 'originalFilename',
          class: 'lightBg msaBlue--text',
          width: '20%',
        },
        {
          text: 'Folder',
          align: 'left',
          value: 'groupName',
          class: 'lightBg msaBlue--text',
          width: '18%',
        },
        {
          text: 'Archived Date',
          align: 'center',
          value: 'createdAt',
          class: 'lightBg msaBlue--text',
          width: '128px',
        },
        {
          text: 'Archived By',
          align: 'center',
          value: 'createdByName',
          class: 'lightBg msaBlue--text',
          width: '150px',
        },
      ],
    };
  },
  methods: {
    downloadDocument(doc) {
      const params = {
        id: this.isForPublicDocument
          ? doc.publicLibraryDocumentId
          : doc.privateLibraryDocumentId,
        loaderText: 'loading...',
        revision: doc.revision,
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      const url = this.isForPublicDocument
        ? 'download-public-document?format=json'
        : 'download-private-library-document?format=json';

      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, doc.originalFilename);
        })
        .catch((error) => error);
    },
  },
};
</script>

<style></style>
