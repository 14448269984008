<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text mb-6">Details</v-card-title>
          <v-form ref="form">
            <v-row>
              <v-col cols="3" align="right" class="mt-2"> Name </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="edited.name"
                  dense
                  counter
                  maxlength="1000"
                  outlined
                  placeholder="Enter document name"
                  :rules="rules.name"
                  data-testid="document-name"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" align="right" class="mt-2"> Folder </v-col>
              <v-col cols="6">
                <CustomizedAutoComplete
                  :value="edited.publicLibraryGroupId"
                  :items="groups"
                  hide-details
                  label="Click to select a folder"
                  :rules="rules.group"
                  @change="edited.publicLibraryGroupId = $event"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" align="right" class="mt-2"> File </v-col>
              <v-col cols="6">
                <v-row no-gutters align="center">
                  <v-col v-if="!isNew || (isNew && file)" class="grow">
                    <a @click="downloadDocument(edited)">
                      {{ edited.originalFilename }}
                    </a>
                  </v-col>
                  <v-col
                    :class="isNew ? (file ? 'shrink' : 'grow') : 'shrink'"
                    align="right"
                  >
                    <v-btn
                      v-blur
                      :block="isNew && !file"
                      @click="$refs.uploader.click()"
                      class="msaBlue white--text"
                    >
                      {{ isNew ? (file ? 'Replace' : 'Upload') : 'Replace' }}
                    </v-btn>
                    <input
                      :key="uploaderRefreshKey"
                      @change="onFileUpload($event)"
                      accept=".pdf, .doc, .docx, .xls, .xlsx"
                      class="d-none"
                      data-testid="file-input"
                      ref="uploader"
                      type="file"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="isFileSizeAlertVisible">
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-alert
                  @input="
                    isFileSizeAlertVisible = false;
                    uploaderRefreshKey = !uploaderRefreshKey;
                  "
                  dense
                  dismissible
                  type="warning"
                >
                  The maximum file size is 100MBs. Please upload a file that is
                  less than 100MBs.
                </v-alert>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <v-row>
              <v-col cols="3" align="right" class="mt-2"> Expiry Date </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edited.expiryDate"
                      readonly
                      dense
                      outlined
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      placeholder="Click to add expiry date"
                      @click:clear="edited.expiryDate = ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="edited.expiryDate"
                    scrollable
                    no-title
                    :min="today"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="3" align="right"> Publication Status </v-col>
              <v-col cols="6" align="right">
                <v-btn-toggle
                  v-model="edited.isPublished"
                  mandatory
                  dense
                  color="msaBlue"
                  :style="{ width: '100%' }"
                >
                  <v-btn :value="0" :style="{ width: '50%' }">
                    Not Published
                  </v-btn>
                  <v-btn :value="1" :style="{ width: '50%' }">
                    Published
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col align="right" cols="9">
                <v-btn
                  class="msaBlue white--text"
                  :disabled="disableSave"
                  @click="saveDetails()"
                >
                  save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> Histories </v-card-title>
          <v-card-text class="mt-4">
            <DocumentHistory
              :histories="document.histories"
              isForPublicDocument
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DocumentHistory from '@/components/DocumentHistory.vue';
export default {
  name: 'PublicDocumentDetails',
  components: {
    DocumentHistory,
  },
  computed: {
    isNew() {
      return this.document.id == 0;
    },
    disableSave() {
      if (this.isNew) {
        return (
          this.edited.name.trim().length == 0 ||
          this.edited.publicLibraryGroupId == 0 ||
          this.file == null
        );
      } else {
        return (
          this.edited.name.trim().length == 0 ||
          (JSON.stringify(this.edited) == JSON.stringify(this.document) &&
            this.file == null)
        );
      }
    },
  },
  data() {
    return {
      acceptedMimeTypes: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
      dateMenu: false,
      document: { name: '', publicLibraryGroupId: 0 },
      documentReplaced: false,
      edited: { name: '', publicLibraryGroupId: 0 },
      file: null,
      isFileSizeAlertVisible: false,
      groups: [],
      rules: {
        name: [(v) => this.$helpers.required(v, 'Document name ')],
        group: [(v) => this.$helpers.required(v, 'Document group ')],
      },
      today: this.$helpers.getToday(),
      uploaderRefreshKey: false,
    };
  },
  methods: {
    getDocument() {
      const url = 'get-public-document?format=json';
      const params = {
        documentId: this.$store.getters.selectedDocument.id,
        loaderText: 'Loading...',
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          this.document = response.data.document;
          this.document.expiryDate = this.$options.filters.filterAsLocalDate(
            this.document.expiryDate,
          );
          this.groups = response.data.groups;

          this.edited = { ...this.document };
        })
        .catch((error) => error);
    },
    async onFileUpload(e) {
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      try {
        file = await this.$helpers.processFile(
          file,
          this.acceptedMimeTypes,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }

      this.file = file;
      this.documentReplaced = true;
      this.edited.originalFilename = file.name;
    },
    saveDetails() {
      if (this.isNew) {
        this.addDocument();
      } else {
        this.updateDocument();
      }
    },
    addDocument() {
      const url = 'add-public-document?format=json';

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('file', this.file);
      params.append('name', this.edited.name);
      params.append('publicLibraryGroupId', this.edited.publicLibraryGroupId);
      params.append('originalFilename', this.file.name);
      params.append('mimeType', this.file.type);
      params.append('expiryDate', this.edited.expiryDate);
      params.append('isPublished', this.edited.isPublished);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      params.append('loaderText', 'Saving...');

      this.$axios
        .post(url, params, headers)
        .then((response) => {
          this.$store.commit('updateSelectedDocument', {
            id: response.data,
            name: this.edited.name,
          });
          this.$router.replace({
            name: 'PublicDocumentDetails',
            params: { id: response.data },
          });
        })
        .catch((error) => error);
    },
    updateDocument() {
      const url = 'update-public-document?format=json';

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();

      params.append('documentId', this.document.id);
      params.append('documentReplaced', this.documentReplaced);
      params.append('expiryDate', this.edited.expiryDate);
      params.append('isPublished', this.edited.isPublished);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('name', this.edited.name);
      params.append('publicLibraryGroupId', this.edited.publicLibraryGroupId);
      params.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);

      if (this.documentReplaced) {
        params.append('file', this.file);
        params.append('originalFilename', this.file.name);
        params.append('mimeType', this.file.type);
      }

      params.append('loaderText', 'Saving...');

      this.$axios
        .post(url, params, headers)
        .then(() => {
          this.getDocument();
          this.file = null;
          this.documentReplaced = false;
          this.$store.commit('updateSelectedDocument', this.document);
        })
        .catch((error) => error);
    },
    downloadDocument(doc) {
      if (this.isNew || this.documentReplaced) {
        const url = URL.createObjectURL(new Blob([this.file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc.originalFilename);
        document.body.appendChild(link);
        link.click();
      } else {
        const params = {
          id: doc.id,
          loaderText: 'Loading...',
        };

        const options = {
          responseType: 'blob',
          withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
        };

        this.$axios
          .post('download-public-document?format=json', params, options)
          .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.originalFilename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => error);
      }
    },
  },
  mounted() {
    this.getDocument();
  },
};
</script>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
